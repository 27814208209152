/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import BreadCrumb from '../components/BreadCrumb'
import { SliceZone } from '../components'

const BlogPost = ({ data: { blogData }, pageContext: { uid }, location }) => {
  const { data: blog } = blogData

  const {
    title,
    description,
  } = blogData

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={title}
        desc={description}
      />
      <section className="hero hero-page">
        <div style={{background: "url(/img/blog-banner.jpg)"}} className="hero-banner"></div>
      </section>
      <section className="blogpost-full">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-xs-12">
              <BreadCrumb path={[{ label: 'Blog', to: `/blog` }, { label: blog.title, to: `/blog/${uid}`}]} />
              <h2 className="mb-5">{blog.title}</h2>
              <Img fluid={blog.image.localFile.childImageSharp.fluid} alt={blog.title} className="img-fluid" />
              <SliceZone allSlices={blogData.data.body} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogPost

export const pageQuery = graphql`
    query BlogItemPageQuery($uid: String!) {
        blogData: prismicBlog(uid: { eq: $uid }) {
            id
            data {
                title
                description
                image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 90) {
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                sizes
                                originalImg
                                originalName
                                presentationWidth
                                presentationHeight
                            }
                        }
                    }
                }
                body {
                    ... on PrismicBlogBodyTwoColumnText {
                        slice_type
                        id
                        primary {
                            left_column {
                                html
                            }
                            right_column {
                                html
                            }
                        }
                    }
                    ... on PrismicBlogBodyText {
                        slice_type
                        id
                        primary {
                            text {
                                html
                                text
                            }
                        }
                    }
                    
                    ... on PrismicBlogBodyQuote {
                        slice_type
                        id
                        primary {
                            text {
                                text
                            }
                        }
                    }
                }
            }
        }
    }
`
